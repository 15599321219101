<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "Historique Location",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Historique-location",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "",
          value: 0,
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],

      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      
      fields: [
        { key: "designation", sortable: true, label: "Nom agence" },
        { key: "tel", sortable: true, label: "Contact" },
        { key: "ifu", label: "IFU" },
        { key: "invoice", sortable: true, label: "Nombres de véhicules" },
        {
          key: "responsable",
          sortable: true,
          label: "Nom & Prénom du dirigeant",
        },
        { key: "email", sortable: true, label: "Email Agence" },
        { key: "tel", sortable: true, label: "Contact" },
        { key: "action", label: "Actions" },
      ],
      showDisable: false,
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,
      infoUser: {
        id: "",
        firstname: "",
        lastname: "",
        profession: "",
        birthday: "",
        adresse: "",
        ville: "",
        pays: "",
        userType: "",
        tel: "",
        email: "",
        personneUrgence: "",
        contactUrgence: "",
        name: "",
        boitePostal: "",
        apropos: "",
        permisCategorie: "",
        permisAnnee: "",
        permisNumuero: "",
      },
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      totalAttente:0,
      totalRefuse : 0,
      allCompleted:[],
      allCanceled:[],
      totalCompleted:0,
      fieldsC: [
      { key: "dateLocation", sortable: true, label: "Date" },
        { key: "designation", sortable: true, label: "Loueur" },
        { key: "telAgence", sortable: true, label: "Contact loueur" },
        { key: "client", sortable: true, label: "Nom client" },
        { key: "telClient", sortable: true, label: "Contact client" },
        { key: "email", sortable: true, label: "Email Agence" },
        { key: "avis", sortable: true, label: "Avis client" },
        { key: "montant", sortable: true, label: "Montant/location" },
        { key: "vehicule", sortable: true, label: "Véhicule / Immatriculation" },
        
        
       // { key: "action", label: "Actions" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
  },
  async mounted() {
    this.init();
    this.completedRequest();
    this.canceledRequest();
  },
  methods: {
    async init() {

      console.log("hi")
    },

    async completedRequest(){
      const response = await apiRequest('GET','location/reservation/effectuees');
      console.log("r", response.data);
      if(response.data && response.status == 200 ){
        console.log("r", response.data)
        this.allCompleted = response.data;
        //const storedData = response.map(r => {})
      }

    },
    async canceledRequest(){
      const response = await apiRequest('GET','location/reservation/annulees');
      console.log("canceled", response.data);
      if(response.data && response.status == 200 ){
        console.log("cencel", response.data)
        this.allCanceled = response.data;
        //const storedData = response.map(r => {})
      }

    },
    async addMessage() {
      this.showMessage = false;
      this.showMessageMail = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );

      if (data && data.data) {
        this.showSucess = true;
        this.init();
      } else {
        this.showEchec = true;
        this.init();
      }
    },

    async ModifSubmit() {
      this.showEdit = false;

      const Editdata = await apiRequest(
        "PUT",
        "users/" + this.infoUser.id,
        {
          idUser: this.infoUser.id,
          lastname: this.infoUser.lastname,
          firstname: this.infoUser.firstname,
          tel: this.infoUser.telephone,
          email: this.infoUser.email,
          adresse: this.infoUser.adresse,
          ville: this.infoUser.ville,
          pays: this.infoUser.pays,
          name: this.infoUser.name,
          boitePostal: this.infoUser.boitePostal,
          birthday: this.infoUser.birthday,
          userType: this.userTypeSelect,
          profession: this.infoUser.profession,
          apropos: this.infoUser.apropos,
          permisCategorie: this.infoUser.permisCategorie,
          permisAnnee: this.infoUser.permisNumuero,
          permisNumuero: this.infoUser.permisNumuero,
          personneUrgence: this.infoUser.contactUrgence,
          contactUrgence: this.infoUser.contactUrgence,
        },
        false
      );

      if (Editdata && Editdata.data) {
        this.showSucessEdit = true;
        this.init();
      } else {
        this.showEchecEdit = true;
        this.init();
      }
    },
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    async editClick(row) {
      this.showEdit = true;
      this.infoUser.id = row.id;

      const dataDetail = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: row.id },
        false
      );

      if (dataDetail && dataDetail.data) {
        this.infoUser.lastname = dataDetail.data.personne.lastname;
        this.infoUser.firstname = dataDetail.data.personne.firstname;
        this.infoUser.profession = dataDetail.data.personne.profession;
        (this.infoUser.birthday = format(
          new Date(dataDetail.data.personne.birthday),
          "dd/MM/yyyy hh:mm:ss"
        )),
          (this.infoUser.adresse = dataDetail.data.personne.adresse);
        this.infoUser.ville = dataDetail.data.personne.ville;
        this.infoUser.pays = dataDetail.data.personne.pays;
        this.infoUser.email = dataDetail.data.personne.email;
        this.infoUser.contactUrgence = dataDetail.data.personne.contactUrgence;
        this.infoUser.personneUrgence =
          dataDetail.data.personne.personneUrgence;
        this.infoUser.telephone = dataDetail.data.personne.tel;
        this.infoUser.userTypeSelect = dataDetail.data.personne.userType;
      }
    },
    desactiverUser(row) {
      this.userToUse = row.id;

      this.showDelete = true;
    },
    async accepter(row) {
      this.userToUse = row.id;
      const data = await apiRequest(
        "POST",
        "agence/reponse",
        { idAgence: this.userToUse, reponse: true },
        false
      );

      if (data && data.data) {
        this.init();
      }
    },
    async refuser(row) {
      this.userToUse = row.id;
      const data = await apiRequest(
        "POST",
        "agence/reponse",
        { idAgence: this.userToUse, reponse: false },
        false
      );

      if (data && data.data) {
        this.init();
      }
    },
    async desactiveUser() {
      this.showDelete = false;
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );

      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    messageShow(row, typeMessage) {
      this.messageSelect = typeMessage;

      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      this.messageContent.userId = row.id;
    },
    async activeUser(row) {
      this.userToUse = row.id;
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );

      if (data && data.data) {
        this.position();
        this.init();
      }
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
     
      router.push(`/agence/detail_agence/${row.id}`);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              

            
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Locations effectuées <span class="rounded-circle bg-white text-dark w-50 h-50 p-2"> {{allCompleted.length}}</span></a>
                </template>
                <div
                  class="row d-flex justify-content-between align-items-center mt-4"
                >
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    striped
                    
                    style="cursor: pointer"
                    class="table-centered"
                    :items="allCompleted"
                    :fields="fieldsC"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(client)="row"> 
                      <div>
                        {{ row.item.client.firstname +" "+row.item.client.lastname }}
                      </div>
                    </template>
                    <template v-slot:cell(dateLocation)="row"> 
                      <div>
                        {{ "Du " + new Date(row.item.dateDepart).toLocaleDateString("Fr-fr")+" au "+ new Date(row.item.dateRetour).toLocaleDateString("Fr-fr") }}
                      </div>
                    </template>
                    <template v-slot:cell(designation)="row"> 
                      <div v-if="row.item.vehicule.conducteur === undefined">
                        Utilisateur non trouvé 
                      </div>
                      <div v-else>
                        {{ row.item.vehicule.conducteur.personne.firstname +" "+row.item.vehicule.conducteur.personne.lastname || " non trouvé" }}
                        
                      </div>

                    </template>
                    <template v-slot:cell(telClient)="row"> 
                      <div>
                        <a :href="'https://wa.me/'+row.item.client.indicatifTel.replace('+','')+row.item.client.tel">
                          {{ row.item.client.indicatifTel +" "+row.item.client.tel }}
                        </a>
                        
                      </div>
                      
                    </template>
                    <template v-slot:cell(vehicule)="row"> 
                      <div>
                        {{ row.item.vehicule.marque +" / "+row.item.vehicule.immatriculation}}
                      </div>
                      
                    </template>
                    <template v-slot:cell(montant)="row"> 
                      <div>
                        {{ row.item.fraisReservation}} f
                      </div>
                      
                    </template>
                    <template v-slot:cell(avis)="row"> 
                      <div>
                        {{ row.item.avisdonne || " Pas d'avis"}}
                      </div>
                      
                    </template>
                    <template v-slot:cell(email)="row"> 
                      <div>
                        <a v-if="row.item.vehicule.conducteur == undefined"  href="#">non trouvé</a>
                        <a v-else :href="'mailto:'+row.item.vehicule.conducteur.personne.email">{{ row.item.vehicule.conducteur.personne.email || "non trouvé" }}</a>
                      </div>
                      
                    </template>
                    <template v-slot:cell(telAgence)="row"> 
                      <div v-if="row.item.vehicule.conducteur">
                        <a :href="'https://wa.me/'+row.item.vehicule.conducteur.personne.indicatifTel.replace('+','')+row.item.vehicule.conducteur.personne.tel">
                          {{ row.item.vehicule.conducteur.personne.indicatifTel +" "+row.item.vehicule.conducteur.personne.tel }}
                        </a>
                        
                      </div>
                      <div v-else class="">
                        non trouvé
                      </div>
                      
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="accepter(row.item)"
                        v-b-tooltip.hover
                        title="Accepter"
                      >
                        <i class="fas fa-check"></i>
                      </a>
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRefuse"
                          :per-page="perPage"
                          :rows="allCanceled"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Locations annulées <span class="rounded-circle bg-white text-dark w-50 h-50 p-2"> {{allCanceled.length}}</span></a>
                </template>
                <div
                  class="row d-flex justify-content-between align-items-center mt-4"
                >
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="allCanceled"
                    :fields="fieldsC"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                  <template v-slot:cell(client)="row"> 
                      <div>
                        {{ row.item.client.firstname +" "+row.item.client.lastname }}
                      </div>
                    </template>
                    <template v-slot:cell(dateLocation)="row"> 
                      <div>
                        {{ "Du " + new Date(row.item.dateDepart).toLocaleDateString("Fr-fr")+" au "+ new Date(row.item.dateRetour).toLocaleDateString("Fr-fr") }}
                      </div>
                    </template>
                    <template v-slot:cell(designation)="row"> 
                      <div>
                        {{ row.item.vehicule.conducteur.personne.firstname +" "+row.item.vehicule.conducteur.personne.lastname }}
                      </div>

                    </template>
                    <template v-slot:cell(telClient)="row"> 
                      <div>
                        <a :href="'https://wa.me/'+row.item.client.indicatifTel.replace('+','')+row.item.client.tel">
                          {{ row.item.client.indicatifTel +" "+row.item.client.tel }}
                        </a>
                        
                      </div>
                      
                    </template>
                    <template v-slot:cell(vehicule)="row"> 
                      <div>
                        {{ row.item.vehicule.marque +" / "+row.item.vehicule.immatriculation}}
                      </div>
                      
                    </template>
                    <template v-slot:cell(montant)="row"> 
                      <div>
                        {{ row.item.fraisReservation}} f
                      </div>
                      
                    </template>
                    <template v-slot:cell(avis)="row"> 
                      <div>
                        {{ row.item.avisdonne || " Pas d'avis"}}
                      </div>
                      
                    </template>
                    <template v-slot:cell(email)="row"> 
                      <div>
                        <a :href="'mailto:'+row.item.vehicule.conducteur.personne.email"></a>
                        {{ row.item.vehicule.conducteur.personne.email  }}
                      </div>
                      
                    </template>
                    <template v-slot:cell(telAgence)="row"> 
                      <div>
                        <a :href="'https://wa.me/'+row.item.vehicule.conducteur.personne.indicatifTel.replace('+','')+row.item.vehicule.conducteur.personne.tel">
                          {{ row.item.vehicule.conducteur.personne.indicatifTel +" "+row.item.vehicule.conducteur.personne.tel }}
                        </a>
                        
                      </div>
                      
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="accepter(row.item)"
                        v-b-tooltip.hover
                        title="Accepter"
                      >
                        <i class="fas fa-check"></i>
                      </a>
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRefuse"
                          :per-page="perPage"
                          :rows="allCanceled"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer cette agence ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucessEdit"
      title="Agence modifiée avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessEdit = false"
          >OK</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecEdit"
      title="La modification a échouée"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecEdit = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer véhicule"
      title-class="font-18"
      hide-footer
    >
      <form enctype="multipart/form-data" @submit.prevent="submitCar">
        <div class="row">
          <div class="col-md-6">
            <label for="">Nom de l'agence</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Nom du dirigeant</label>
            <input type="text" class="form-control" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">RCCM</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Prénom</label>
            <input type="text" class="form-control" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">IFU</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Contact</label>
            <input type="text" class="form-control" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Contact</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Email</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6 mt-4">
            <label for="">Nombre de véhicule</label>
            <input type="text" class="form-control" id="" />
          </div>
          <div class="col-md-6 mt-4">
            <label for="">Mot de passe</label>
            <input type="text" class="form-control" id="" />
          </div>
        </div>
        <div class="form-group mt-4">
          <button class="btn btn-primary float-right">Modifier</button>
        </div>
      </form>
    </b-modal>

    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <textarea
            v-model="messageContent.message"
            placeholder="Votre message"
            class="form-control"
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- ENVOYER LE MESSAGE MAIL-->
    <b-modal v-model="showMessageMail" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3AAA35;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
   background: #dc143c;
   transition: all .5s;
 }
 
 .nav-tabs .nav-link a{
  color: #fff ;
} 

.text-red{
  color:#dc143c;
}  .nav-tabs .nav-link a{
  color: #fff!important ;
}

.text-green{
  color:#3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: blue;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid blue;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}
</style>
